<template>
    <div class="row" v-if="collector">

        <div class="col-xl-3">
        <div class="card mb-3 flex-1">
        
        <div class="card-body">
        <div class="d-flex mb-3">
        <div class="flex-grow-1">
        <h5 class="mb-1">Total Collections</h5>
        <div>Number of Collections </div>
        </div>
        <a href="#"  @click.prevent="refreshcollectordata" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
        </div>
        <div class="d-flex">
        <div class="flex-grow-1">
        <h3 class="mb-1">{{ collector.collections_count }}</h3>
        
        </div>
        <div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
        <i class="fa fa-user fa-lg text-primary"></i>
        </div>
        </div>
        </div>
        
        </div>
        
        
        
        </div>
        
        
        <div class="col-xl-3">
        <div class="card mb-3 flex-1">
        <div class="card-body">
        <div class="d-flex mb-3">
        <div class="flex-grow-1">
        <h5 class="mb-1">Total Verified</h5>
        <div>All Validated Collections</div>
        </div>
        <a href="#"  @click.prevent="refreshcollectordata" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
        </div>
        <div class="d-flex">
        <div class="flex-grow-1">
        <h3 class="mb-1">{{ collector.validatedcollections_count }}</h3>
        
        </div>
        <div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
        <i class="fa fa-user fa-lg text-primary"></i>
        </div>
        </div>
        </div>
        
        </div>
        </div>
        
        
        
        
        <div class="col-xl-3">
        <div class="card mb-3 flex-1">
        <div class="card-body">
        <div class="d-flex mb-3">
        <div class="flex-grow-1">
        <h5 class="mb-1">Total Earnings</h5>
        <div>Total Amount Earned</div>
        </div>
        <a href="#"  @click.prevent="refreshcollectordata" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
        </div>
        <div class="d-flex">
        <div class="flex-grow-1">
        <h3 class="mb-1">UGX: {{ collector.wallet?.total }}</h3>
        
        </div>
        <div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
        <i class="fa fa-user fa-lg text-primary"></i>
        </div>
        </div>
        </div>
        
        </div>
        </div>
        
        
        
        <div class="col-xl-3">
        <div class="card mb-3 flex-1">
        <div class="card-body">
        <div class="d-flex mb-3">
        <div class="flex-grow-1">
        <h5 class="mb-1">Total Withdrew</h5>
        <div>Amount Taken Out</div>
        </div>
        <a href="#" @click.prevent="refreshcollectordata" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
        </div>
        <div class="d-flex">
        <div class="flex-grow-1">
        <h3 class="mb-1">UGX: {{ collector.wallet?.withdrew }}</h3>
        
        </div>
        <div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
        <i class="fa fa-user fa-lg text-primary"></i>
        </div>
        </div>
        </div>
        
        </div>
        </div>
        
        
        
        
        
        
        
        </div>

    <h1 class="page-header">
    Manage collections From Here 
    </h1>
    <hr class="mb-4" />
    
    
    
    <div class="card  bg-default border-primary  mb-4 hideall" v-if="collection" id="updatecollectiondata">
        <div class="card-header"><h5 class="card-title">Update collection Data</h5></div>
        <div class="card-body">
            <form @submit.prevent="updateforminput" >
            <div class="form-group row mb-3">
            <div class="col-sm-4">
                <label class="form-label" for="exampleFormControlInput1">First Name</label>
                <input type="text" v-model="collection.f_name" class="form-control" id="exampleFormControlInput1" placeholder="Enter  Title" required>
                <i class="text-warning" v-if="formerror.f_name">{{ formerror.f_name }}</i>
            </div>
    
            <div class="col-sm-4">
                <label class="form-label" for="exampleFormControlInput1">Last Name</label>
                <input type="text" v-model="collection.l_name" class="form-control" id="exampleFormControlInput1" placeholder="Enter Zip Code" required>
                <i class="text-warning" v-if="formerror.l_name">{{ formerror.l_name }}</i>
            </div>


            <div class="col-sm-4">
                <label class="form-label" for="exampleFormControlInput1">Email Address</label>
                <input type="email" v-model="collection.email" class="form-control" id="exampleFormControlInput1" placeholder="Enter Zip Code">
                <i class="text-warning" v-if="formerror.email">{{ formerror.email }}</i>
            </div>

            <div class="col-sm-4 mt-2">
                <label class="form-label" for="exampleFormControlInput1">Phone Number</label>
                <input type="number" v-model="collection.phone" class="form-control" id="exampleFormControlInput1" placeholder="Enter  Collection Phone Number" required>
                <i class="text-warning" v-if="formerror.phone">{{ formerror.phone }}</i>
            </div>
    
            <div class="col-sm-4 mt-2">
                <label class="form-label" for="exampleFormControlInput1">Whatsapp Number</label>
                <input type="number" v-model="collection.whatsapp" class="form-control" id="exampleFormControlInput1" placeholder="Enter Zip Code">
                <i class="text-warning" v-if="formerror.whatsapp">{{ formerror.whatsapp }}</i>
            </div>


            <div class="col-sm-4 mt-2">
                <label class="form-label" for="exampleFormControlInput1">Date of Birth</label>
                <input type="text" v-model="collection.dob" class="form-control" id="exampleFormControlInput1" placeholder="Enter Zip Code">
                <i class="text-warning" v-if="formerror.dob">{{ formerror.dob }}</i>
            </div>


            <div class="col-sm-6 mt-2" style="position:relative" v-if="professions">
                <label class="form-label" for="exampleFormControlInput1">Select Collection Profession</label>
                <div style="position:relative">
                <input class="form-control" v-model.trim="userprofession" @input="createprofessionsearchlist" placeholder="Enter the Name of the Profession..." />
                <div v-if="loading == 'getsearchedprofessions'" style="position:absolute; right:15px; bottom:5px"><i class="fas fa-circle-notch fa-spin" style="font-size:20px"></i></div>
                </div>
                <div class="hideall" id="professionlist">
                <div class="list-group scrollbarslect" style="position:absolute; width:97% !important; z-index:999999999999999999999999999 !important; height:200px; overflow:auto"  v-if="professions.data.length">
                    <a href="#" v-for="profession in professions.data" :key="profession" @click.prevent="selectprofessionforupdate(profession)" :class="{'active': collection.profession_id == selectedprofession_id}" class="list-group-item list-group-item-action">{{ profession.title }}</a>
                </div>
                <div class="list-group" style="position:absolute;" v-else>
                    <a href="#"  class="list-group-item list-group-item-action">No professions found with that title. Try a different Search</a>
                </div>
                </div>
              
                <i class="text-warning" v-if="formerror.profession_id">{{ formerror.profession_id }}</i>
            </div>

            <div class="col-sm-6 mt-2" style="position:relative" v-if="villages">
                <label class="form-label" for="exampleFormControlInput1">Select Collection Village</label>
                <div style="position:relative">
                <input class="form-control" v-model.trim="uservillage" @input="createvillagesearchlist" placeholder="Enter the Name of the Village..." />
                <div v-if="loading == 'getsearchedvillages'" style="position:absolute; right:15px; bottom:5px"><i class="fas fa-circle-notch fa-spin" style="font-size:20px"></i></div>
                </div>
                <div class="hideall" id="villagelist">
                <div class="list-group scrollbarslect" style="position:absolute; width:97% !important; z-index:999999999999999999999999999 !important; height:200px; overflow:auto"  v-if="villages.data.length">
                    <a href="#" v-for="village in villages.data" :key="village" @click.prevent="selectvillageforupdate(village)" :class="{'active': collection.village_id == selectedvillage_id}" class="list-group-item list-group-item-action">{{ village.title }}({{ village.division.title }} - {{ village.district.title }})</a>
                </div>
                <div class="list-group" style="position:absolute;" v-else>
                    <a href="#" @click.prevent="selectvillage" class="list-group-item list-group-item-action">No Villages found with that Name. Try a different Search</a>
                </div>
                </div>
              
                <i class="text-warning" v-if="formerror.village_id">{{ formerror.village_id }}</i>
            </div>
            
        </div>

        <div  style="float:right" >
            <button type="submit" @click.prevent="cancelprocess" class="btn btn-danger btn-block" style="margin-right:6px">Close Form</button>
            <button type="submit" class="btn btn-indigo btn-block">Update Collection information </button>
        </div>
        </form>
    
    
        </div>
    </div>
    
    
    
    <div class="row">
    
    <div class="card">
    <ul class="nav nav-tabs nav-tabs-v2 px-4">
    <li class="nav-item me-3"><a href="#allTab"  class="nav-link  px-2" :class="{'active' : filtertype == 'all'}"  @click.prevent="changefilterlist('all')"  data-bs-toggle="tab">All</a></li>
    <li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" :class="{'active' : filtertype == 'active'}" @click.prevent="changefilterlist('active')">Active </a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2"   :class="{'active' : filtertype == 'suspended'}" @click.prevent="changefilterlist('suspended')">Suspended</a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2"   :class="{'active' : filtertype == 'validated'}" @click.prevent="changefilterlist('validated')">Validated</a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2"   :class="{'active' : filtertype == 'unvalidated'}" @click.prevent="changefilterlist('unvalidated')">Non Validated</a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2"    @click.prevent="getcollectionlistbyuserpage(1)"><i class="fas fa-redo"></i></a></li>
    </ul>
    
    
    <div class="tab-content p-4">
    <div class="tab-pane fade show active" id="allTab">
    
    <div class="input-group mb-4">
    <div class="flex-fill position-relative">
    <div class="input-group">
    <input type="text" v-model.trim="search" class="form-control ps-35px" placeholder="Search by Name" />
    <div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
    <i class="fa fa-search opacity-5"></i>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="table-responsive">
    <table class="table table-hover text-nowrap">
    <thead>
    <tr>
    <th class="border-top-0 pt-0 pb-2">#</th>
    <th class="border-top-0 pt-0 pb-2">Collection Name</th>
    <th class="border-top-0 pt-0 pb-2">Resident Village</th>
    <th class="border-top-0 pt-0 pb-2">Profession</th>
    <th class="border-top-0 pt-0 pb-2">Phone</th>
    <th class="border-top-0 pt-0 pb-2">Submited By</th>
    <th class="border-top-0 pt-0 pb-2">Validated</th>
    <th class="border-top-0 pt-0 pb-2">Status</th>
    <th class="border-top-0 pt-0 pb-2">Action</th>
    </tr>
    </thead>
    <tbody v-if="collections">
    
    <tr v-for="(item, index) in filteredlist" :key="item.id" style="cursor:pointer !important" >
    
        <td class="align-middle">{{ index + 1}}</td>
        <td class="align-middle"><router-link :to="{name:'collectiondetails', params:{id:item.id}}">{{ item.f_name }} {{ item.l_name }}</router-link></td>
        <td class="align-middle" >{{ item.village?.title }}- {{ item.country?.title }}</td>
        <td class="align-middle" >{{ item.profession?.title }}</td>
        <td class="align-middle" >{{ item.phone }}</td>
        <td class="align-middle" >{{ item.user?.name }}</td>
        <td class="align-middle" >
            <button v-if="item.verify == 1"  class="badge btn btn-success  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Validated</button>
            <button v-else  @click.prevent="validatecontact(item.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Validate</button>
        </td>
        <td class="align-middle" >
            <button v-if="item.status == 1" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Hide</button>
            <button v-if="item.status == 0" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unhide</button>
        </td>
    
    
        <td class="align-middle">
      
        <button @click.prevent="getcollectionforupdate(item.id)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>
        <button @click.prevent="getcollectionfordelete(item.id)"  class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Delete</button>
       </td>
    
    </tr>
    
    
    
    
    </tbody>
    </table>
    </div>
    
    
    <div class="d-md-flex align-items-center" v-if="collections">
    <div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
    Showing {{ collections.data.length}} to {{ collections.per_page}} of {{ collections.total}} entries
    </div>
    <ul class="pagination mb-0 justify-content-center">
    
    <li class="page-item" v-for="(link, index) in collections.links" :key="link.id">
        <a v-if="collections.last_page >= index && index != 0 " @click.prevent="getpageitems(index)" class="page-link" href="#">{{ index}}</a>
    </li>
    
    </ul>
    </div>
    
    
    
    </div>
    </div>
    </div>
    
    
    
    </div>
    
    
    </template>
    <script>
    import {mapGetters, mapActions } from 'vuex'
    export default {
    
        data(){
            return{
                forminput:{
                    title: '',
                    zipcode:'',
                },
                formerror:{
                    f_name: '',
                    l_name:'',
                    email: '',
                    phone:'',
                    whatsapp: '',
                    dob:'',
                    village_id:'',
                    profession_id:''
                },
    
                lastpage:'',
                currentpage:1,
                nextpage:'',
                filtertype:'',
                search:'',
                uservillage:'',
                selectedvillage_id:'',
                userprofession:'',
                selectedprofession_id:''
                
            }
        },
    
    
    
         watch:{
            search(){
                if(this.search.length){
                    if(this.search.length > 2){
                        this.searchbtncollections()
                    }
                }
            },

   

        },
    
    
    
         computed:{
            ...mapGetters(['msg', 'error','pageloader','loading', 'collections', 'collection','villages','professions','collector']),
    
                filteredlist(){
                    if(this.filtertype == 'active'){
                        return this.collections.data.filter(co => co.status == 1)
                    }else if(this.filtertype == 'suspended'){
                        return this.collections.data.filter(co => co.status == 0)
                    }else if(this.filtertype == 'unvalidated'){
                        return this.collections.data.filter(co => co.verify == 0)
                    }else if(this.filtertype == 'validated'){
                        return this.collections.data.filter(co => co.verify == 1)
                    }else {
                        return this.collections.data
                    }
                },
            },
    
    
        methods:{
            ...mapActions(['savecollection','getcollectionlist', 'getcollection','updatecollection','getserachedcollections','changecollectionstatus','validatecollection','getsearchedvillages','getvillagelist','getprofessionlist','getsearchprofessionslist','deletecollection','getsearchedcollections','getcollectionlistbyuser', 'getcollector']),


            getpageitems(value){
                this.currentpage = value;
                let page = value;
                this.getcollectionlistbyuserpage(page)
            },
            

            getcollectionlistbyuserpage(page){
                let id = this.$route.params.id
                if(id){
                this.getcollectionlistbyuser({page, id})
                }
                
            },

            changefilterlist(value){
                this.filtertype = value;
            },
    
            searchbtncollections(){
                  if(this.search == ''){
                  }else{
                      let data = {
                        search: this.search
                        }
                        
                        this.getsearchedcollections(data);
                  }   
            },

            createvillagesearchlist(){
                if(this.uservillage.length){
                    $('#villagelist').removeClass('hideall')
                    if(this.uservillage.length > 1){
                        this.getserachvillageslist()
                    }else{
                        this.getserachvillageslist()
                    }
    
                }else{
                    this.getvillagelist()
                }
            },

            getserachvillageslist(){
                  if(this.uservillage == ''){
                  }else{
                      let data = {
                        search: this.uservillage
                        }
                        
                        this.getsearchedvillages(data);
                  }   
            },

            selectvillageforupdate(value){
               this.uservillage = value.title;
               this.selectedvillage_id = value.id;
               this.collection.village_id = value.id
               $('#villagelist').addClass('hideall')
            },


            ///professions
            createprofessionsearchlist(){
                if(this.userprofession.length){
                    $('#professionlist').removeClass('hideall')
                    if(this.userprofession.length > 1){
                        this.getserachprofessionslist()
                    }else{
                        this.getserachprofessionslist()
                    }
    
                }else{
                    this.getprofessionlist()
                }
            },

            getserachprofessionslist(){
                  if(this.userprofession == ''){
                  }else{
                      let data = {
                        search: this.userprofession
                        }
                        
                        this.getsearchprofessionslist(data);
                  }   
            },

            selectprofessionforupdate(value){
               this.userprofession = value.title;
               this.selectedprofession_id = value.id;
               this.collection.profession_id = value.id
               $('#professionlist').addClass('hideall')
            },



            updateforminput(){
                let id = this.collection.id;
                if(this.collection.f_name == ''){
                this.formerror.f_name = 'What is the User Profession';
                setTimeout(() => this.collection.f_name = '', 5000)
                }else if(this.collection.l_name == ''){
                this.formerror.l_name = 'What is the User Profession';
                setTimeout(() => this.collection.l_name = '', 5000)
                }else if(this.collection.phone == ''){
                this.formerror.phone = 'Phone Number is Required';
                setTimeout(() => this.collection.phone = '', 5000)
                }else if(this.collection.profession_id == ''){
                this.formerror.profession_id = 'What is the User Profession';
                setTimeout(() => this.collection.profession_id = '', 5000)
                }else if(this.collection.parish_id == ''){
                this.formerror.village_id = 'Select a Village you Stay';
                setTimeout(() => this.formerror.village_id = '', 5000)
                }else{
                    let data = {
                        f_name:this.collection.f_name,
                        l_name:this.collection.l_name,
                        email:this.collection.email,
                        phone:this.collection.phone,
                        whatsapp:this.collection.whatsapp,
                        dob:this.collection.dob,
                        profession_id:this.collection.profession_id,
                        gender_id:this.collection.gender_id,
                        village_id:this.collection.village_id,
                    
                    }
                this.updatecollection({data, id}).then(()=>{
                    let page = this.currentpage;
                    this.getcollectionlistbyuserpage(page)
                    this.cancelprocess();
                })
                }
               
            },

            refreshcollectordata(){
                let id = this.$route.params.id
                if(id){
                this.getcollector(id).then(()=>{
        
                })
                }
            },



            validatecontact(id){
                this.validatecollection(id).then((response)=>{
                    let page = this.currentpage;
                    this.getcollectionlistbyuserpage(page)
                })
            },
    
    
            getcollectionforupdate(id){
                this.getcollection(id).then(()=>{
                    this.getvillagelist();
                    this.getprofessionlist();
                    this.userprofession = this.collection.profession?.title;
                    this.uservillage = this.collection.village?.title;
                    $('#updatecollectiondata').removeClass('hideall')
                    this.showupdateform();
                })
    
            },


            getcollectionfordelete(id){
                this.deletecollection(id).then(()=>{
                    let page = this.currentpage;
                    this.getcollectionlistbyuserpage(page)
                    $('#updatecollectiondata').removeClass('hideall')
                    this.showupdateform();
                })
            },
    
    
            changeitemstatusbtn(id){
                this.changecollectionstatus(id).then(()=>{
                    let page = this.currentpage;
                    this.getcollectionlistbyuserpage(page)
                })
            },
    
    
            showcreateformform(){
                $('#updateoldform').addClass('hideall')
                $('#createnewbtn').addClass('hideall')
                $('#createnewform').removeClass('hideall')
                $('#closebtn').removeClass('hideall')
            },
    
            showupdateform(id){
                $('#updateoldform').removeClass('hideall')
                $('#createnewbtn').addClass('hideall')
                $('#createnewform').addClass('hideall')
                $('#closebtn').removeClass('hideall')
            },
    
    
            cancelprocess(){
                $('#updatecollectiondata').addClass('hideall')
            },
        },
    
        
    
        created(){
            let id = this.$route.params.id
            if(id){
            this.getcollector(id).then(()=>{
                let page = this.currentpage;
            this.getcollectionlistbyuserpage(page)
            })
            
            }else{

            }
        }
    
    }
    </script>
    <style>

.scrollbarslect {
    scrollbar-width: auto;
    scrollbar-color: #221f23 #ffffff;
  }

.scrollbarslect::-webkit-scrollbar {
    width: 16px;
  }

.scrollbarslect::-webkit-scrollbar-track {
    background: #ffffff;
  }

.scrollbarslect::-webkit-scrollbar-thumb {
    background-color: #221f23;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
    </style>